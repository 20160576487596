<template>
  <div>
    <master-component
      ref="masterComponent"
      :config="config"
      :showAdd="false"
      :showEdit="false"
      @clicked-search="clickedSearch"
    >
      <template v-slot:master-component-center>
        <v-row>
          <v-col class="text-right">
            <v-btn
              class="mt-3"
              color="success"
              :disabled="items.length === 0"
              @click="downloadFile"
            >ดาวน์โหลดข้อมูล</v-btn>
          </v-col>
        </v-row>
      </template>
    </master-component>
  </div>
</template>

<script>
import {
  InputType,
  StatusCase,
  UserRole,
  StatusPayment,
  Customer
} from "./../js/constants";
import moment from "moment";

import CustomDialog from "./../components/custom/custom-dialog";
import { createDynamicModel } from "./../js/custom";
import { momentToThaiFormat } from "./../js/date-helper";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";

export default {
  components: {
    "custom-dialog": CustomDialog
  },
  data() {
    return {
      criteria: {},
      config: {},
      items: []
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "billing",
        shortName: "billing",
        search: this.searchSection,
        form: {},
        url: this.urlSection,
        table: this.tableSection,
        permission: {}
      };
    },
    clickedSearch(criteria) {
      this.criteria = criteria;
      this.$nextTick(() => {
        this.items = this.$refs.masterComponent.getItems();
      });

      if (criteria.customer.id === Customer.AIRA_ID) {
        this.config.table = getAIRA();
      } else if (criteria.customer.id === Customer.AEON_ID) {
        this.config.table = getAEON();
      }
    },
    downloadFile() {
      let url = "/report/issue/download";
      this.$http.downloadFile(url, this.criteria);
    }
  },
  computed: {
    urlSection() {
      let searchUrl = "/report/issue/search";

      return {
        search: searchUrl
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "customer",
              text: "ลูกค้า",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/customer/report"
              },
              default: {
                id: Customer.AEON_ID
              },
              rule: {
                required: true
              }
            },
            {
              name: "reportType",
              text: "ประเภท",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/reportTypes"
              },
              rule: {
                required: true
              }
            },
            {
              name: "dataDate",
              text: "ข้อมูลตั้งแต่วันที่",
              type: InputType.begin_end_date,
              default: {
                begin: momentToThaiFormat({
                  moment: moment().subtract(1, "months")
                }),
                end: momentToThaiFormat({ moment: moment() })
              },
              columnClass: "col-12 col-sm-12 col-md-6"
            },
            /*
              type: InputType.offset
            },
            {
              type: InputType.offset
            }*/
          ],
          /*[
            {
              name: "dataDate",
              text: "ข้อมูลตั้งแต่วันที่",
              type: InputType.begin_end_date,
              default: {
                begin: momentToThaiFormat({
                  moment: moment().subtract(1, "months")
                }),
                end: momentToThaiFormat({ moment: moment() })
              },
              columnClass: "col-sm-12 col-md-6"
            },
            {
              type: InputType.offset
            },
            {
              type: InputType.offset
            }
          ]*/
        ]
      };
    },
    tableSection() {
      return {
        showColumnAction: false
      };
    }
  }
};

function getAEON() {
  return {
    showColumnAction: false,
    columns: [
      {
        name: "date1_s",
        text: "วันรับงาน"
      },
      {
        name: "idCardNo",
        text: "เลขบัตรประชาชน"
      },
      {
        name: "name",
        text: "ชื่อ-สกุล"
      },
      {
        name: "productType",
        text: "โปรดักส์บัตร"
      },
      {
        name: "agreementNo",
        text: "เลขที่สัญญา"
      },
      {
        name: "principalAmount_s",
        text: "ทุนทรัพย์",
        numeric: 2,
        class: "text-right"
      },
      {
        name: "courtName",
        text: "ศาล"
      },
      {
        name: "lawyerName",
        text: "ทนายฟ้อง"
      },
      {
        name: "dateFong",
        text: "ยื่นฟ้อง"
      },

      {
        name: "blackNumber",
        text: "เลขคดีดำ"
      },
      {
        name: "courtFeeAmount_s",
        text: "ค่าธรรมเนียมศาล",
        numeric: 2,
        class: "text-right"
      },
      {
        name: "songKooKwamAmount_s",
        text: "ค่าส่งคำคู่ความ",
        numeric: 2,
        class: "text-right"
      },
      {
        name: "splitAmount1_s",
        text: "แยกยอดตามสัญญา(ใบเสร็จทางราชการ)",
        numeric: 2,
        class: "text-right"
      },
      {
        name: "berk1_s",
        text: "เบิกตามจริง(ใบเสร็จทางราชการ)",
        numeric: 2,
        class: "text-right"
      },
      {
        name: "splitAmount2_s",
        text: "แยกยอดตามสัญญาค่าดำเนินคดี",
        numeric: 2,
        class: "text-right"
      },
      {
        name: "berk2_s",
        text: "ค่าใช้จ่ายในการดำเนินคดี",
        numeric: 2,
        class: "text-right"
      },
      {
        name: "grandTotal_s",
        text: "รวมทั้งสิ้น",
        numeric: 2,
        class: "text-right"
      }
    ]
  };
}

function getAIRA() {
  return {
    showColumnAction: false,
    columns: [
      {
        name: "aira1",
        text: "aira1"
      },
      {
        name: "aira2",
        text: "aira2"
      },
      {
        name: "aira3",
        text: "aira3"
      }
    ]
  };
}
</script>
